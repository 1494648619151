import axios from "axios";

const service = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 900000,
});

const getToken = () => {
    const token = localStorage.getItem('token');

    return token
}

export const getAllBrands = async (payload = null) => {
    try {
        const data = await service.request('company/listAllBrandsAdmin', {
            params: payload,
            headers: {
                Authorization: getToken()
            },
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const getAllContent = async (payload = null) => {
    try {
        const data = await service.request('academy/content/findAllAdmin', {
            params: payload,
            headers: {
                Authorization: getToken()
            },
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const postCreateVimeoVideo = async (videoInfo, video = null, thumbnail = null) => {
    try {
        const formData = new FormData();
        formData.set("video", video);
        formData.set("thumbnail", thumbnail);
        formData.set("videoInfo", JSON.stringify(videoInfo));

        const data = await service.request('academy/content/createVimeoVideo', {
            method: 'post',
            headers: {
                Authorization: getToken(),
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const getAllStates = async () => {
    try {
        const data = await service.request('state/findAll', {
            headers: {
                Authorization: getToken()
            },
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const getReports = async () => {
    try {
        const data = await service.request('exportCsv/findAll', {
            headers: {
                Authorization: getToken()
            },
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const getAllTags = async () => {
    try {
        const data = await service.request('academy/tags', {
            headers: {
                Authorization: getToken()
            },
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const postContentTags = async (payload) => {
    try {
        const data = await service.request('academy/content/createTagsContent', {
            method: 'post',
            headers: {
                Authorization: getToken()
            },
            data: payload,
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const changeActiveContent = async (payload, idContent) => {
    try {
        const data = await service.request(`academy/content/statusContent/${idContent}`, {
            method: 'put',
            headers: {
                Authorization: getToken()
            },
            data: payload,
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const getTip = async () => {
    try {
        const data = await service.request('tip/find', {
            headers: {
                Authorization: getToken()
            },
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const getSchedule = async () => {
    try {
        const data = await service.request('schedule/findAll', {
            headers: {
                Authorization: getToken()
            },
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const updateTip = async (payload) => {
    try {
        const data = await service.request('tip/update', {
            method: 'post',
            headers: {
                Authorization: getToken()
            },
            data: payload,
        });
        return data;
    } catch (error) {
        return error.response
    }
}

export const findBuyerCategory = async () => {
    try {
        const data = await service.request('category/findBuyerCategory', {
            headers: {
                Authorization: getToken()
            },
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const getBusinessIntelligenceById = async (payload = null) => {
    try {
        const data = await service.request(`businessIntelligence/${payload}`, {
            headers: {
                Authorization: getToken()
            },
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const getAllBuyers = async (payload = null) => {
    try {
        const data = await service.request('company/listAllBuyersAdmin', {
            params: payload,
            headers: {
                Authorization: getToken()
            },
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const getTypeKnown = async (payload = null) => {
    try {
        const data = await service.request('typeKnown/findAll', {
            headers: {
                Authorization: getToken()
            },
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const counterCompanyAdmin = async () => {
    try {
        const data = await service.request('company/counterCompanyAdmin', {
            headers: {
                Authorization: getToken()
            },
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const searchCompanyByCnpj = async (payload = null) => {
    try {
        const data = await service.request('company/searchCompanyByCnpj', {
            method: 'post',
            headers: {
                Authorization: getToken()
            },
            data: payload,
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const updateBiId = async (payload = null) => {
    try {
        const data = await service.request('company/updateBi', {
            method: 'post',
            headers: {
                Authorization: getToken()
            },
            data: payload,
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const createSchedule = async (payload) => {
    try {
        const data = await service.request('schedule/create', {
            method: 'post',
            headers: {
                Authorization: getToken()
            },
            data: payload,
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const updateSchedule = async (payload) => {
    try {
        const data = await service.request('schedule/update', {
            method: 'post',
            headers: {
                Authorization: getToken()
            },
            data: payload,
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const deleteSchedule = async (scheduleId) => {
    try {
        const data = await service.request(`schedule/delete/${scheduleId}`, {
            method: 'delete',
            headers: {
                Authorization: getToken()
            },
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const updateLogoBi = async (payload = null) => {
    try {
        const data = await service.request('businessIntelligence/updateLogoBi', {
            method: 'post',
            headers: {
                Authorization: getToken(),
                "Content-Type": "multipart/form-data"
            },
            data: payload,
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const getAllBusinessIntelligence = async (payload = null) => {
    try {
        const data = await service.request('businessIntelligence/findAllIntelligence', {
            method: 'post',
            headers: {
                Authorization: getToken()
            },
            data: payload,
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const statusUpdateBI = async (payload = null) => {
    try {
        const data = await service.request('businessIntelligence/statusUpdate', {
            method: 'post',
            headers: {
                Authorization: getToken()
            },
            data: payload,
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const getCompany = async (payload = null) => {
    try {
        const data = await service.request('company/findAdmin', {
            method: 'post',
            headers: {
                Authorization: getToken()
            },
            data: payload,
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const updateDeleteCompany = async (payload = null) => {
    try {
        const data = await service.request('company/updateDelete', {
            method: 'post',
            headers: {
                Authorization: getToken()
            },
            data: payload,
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const updateStatusNewContactBuyer = async (payload = null) => {
    try {
        const data = await service.request('company/updateStatusNewContactBuyer', {
            method: 'post',
            headers: {
                Authorization: getToken()
            },
            data: payload,
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const createUpdateContactBuyer = async (payload = null) => {
    try {
        const data = await service.request('company/createUpdateContactBuyer', {
            method: 'post',
            headers: {
                Authorization: getToken()
            },
            data: payload,
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const editCompanyAdmin = async (payload = null) => {
    try {
        const data = await service.request('company/editCompanyAdmin', {
            method: 'post',
            headers: {
                Authorization: getToken()
            },
            data: payload,
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const updateLogo = async (payload = null, logo = null) => {
    try {
        const formData = new FormData();
        formData.set('cnpj', payload.cnpj);
        formData.set('companyId', payload.id);
        formData.set('newLogo', true);
        formData.set('logo', logo);

        const data = await service.request('company/updateLogoAdmin', {
            method: 'post',
            headers: {
                Authorization: getToken(),
                'Content-Type': 'multipart/form-data',
            },
            transformRequest: (data, headers) => {
                // !!! override data to return formData
                // since axios converts that to string
                return formData;
            },
            data: formData,
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const findCitiesByName = async (payload = null) => {
    try {
        const data = await service.request('city/findCitiesByName', {
            method: 'post',
            headers: {
                Authorization: getToken()
            },
            data: payload,
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const createBusinessIntelligence = async (payload, file = null) => {
    try {
        const formData = new FormData();
        formData.append("bi", JSON.stringify(payload));
        formData.append("logo", file);
        const data = await service.request('businessIntelligence/create', {
            method: 'post',
            headers: {
                Authorization: getToken(),
                'Content-Type': 'multipart/form-data',
            },
            transformRequest: (data, headers) => {
                // !!! override data to return formData
                // since axios converts that to string
                return formData;
            },
            data: formData,
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const updateBusinessIntelligence = async (payload = null, file = null) => {
    try {
        const formData = new FormData();
        formData.append("bi", JSON.stringify(payload));
        formData.append("logo", file);
        const data = await service.request('businessIntelligence/update', {
            method: 'post',
            headers: {
                Authorization: getToken(),
                'Content-Type': 'multipart/form-data',
            },
            transformRequest: (data, headers) => {
                // !!! override data to return formData
                // since axios converts that to string
                return formData;
            },
            data: formData,
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const loginAdmin = async (payload) => {
    try {
        const data = await service.request('login/admin', {
            method: 'post',
            responseType: 'json',
            data: payload,
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const userLoginInAdmin = async (payload) => {
    try {
        const data = await service.request('login/emailAdmin', {
            method: 'post',
            responseType: 'json',
            data: payload,
            headers: {
                Authorization: getToken()
            },
        });

        return data;
    } catch (error) {
        return error.response
    }
}

export const fetchProductApi = () => {
    return axios.get(`${process.env.PUBLIC_URL}/api/product.json`);
};

export const fetchChatApi1 = () => {
    return axios.get(`${process.env.PUBLIC_URL}/api/chatMember.json`);
};

export const fetchChatApi2 = () => {
    return axios.get(`${process.env.PUBLIC_URL}/api/chat.chats.json`);
};

export const fetchEmailApi = () => {
    return axios.get(`${process.env.PUBLIC_URL}/api/email.json`);
};

export const fetchBookmaekApi = () => {
    return axios.get(`${process.env.PUBLIC_URL}/api/bookmark.json`);
};

export const fetchTodoApi = () => {
    return axios.get(`${process.env.PUBLIC_URL}/api/todo.json`);
};

export const fetchTaskApi = () => {
    return axios.get(`${process.env.PUBLIC_URL}/api/task.json`);
};

